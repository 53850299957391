.navbar{
    &-toggler{
        &-icon{
            &--colored{
                background-image: $navbar-light-toggler-icon-bg;
            }
        }   
    }

    &-nav{

        & .nav-link{ 
            font-size: 1rem;
            padding: .5rem 0 .4rem 0;
            margin: .5rem;
            color: $dark;
            opacity: .9;
            border-bottom: 1px solid $dark;
            transition: all 0.25s ease;
            
            @include media-breakpoint-up(lg){
                padding: .5rem 0 0 0;
                border-top: 2px solid $dark;
                border-bottom: 0
            }

            &:hover{
                color: $primary;
                border-color: $primary;
                opacity: 1;
            }
        }
        
        & .nav-item.active .nav-link{
            color: $primary;
            border-color: $primary;
            opacity: 1;
        }

    }

    &-brand{
        width: 100px;
        @include media-breakpoint-up(xl){
            width: auto;
            min-width: 221px;
        }
    }
}

.nav{
    &-social{
        & .nav-item{
            width: 2.5rem;
            @include media-breakpoint-up(xl){
                width: 3rem;
            }
        }
        
        & .nav-link{
            padding: 0 .5rem;
            @include media-breakpoint-up(xl){
                padding: .5rem;

                }
        }
    }
}

.card{
    align-items: center;
    justify-content: center;

    &--full{
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;


        & .card-img{
            width: 25%;
            height: auto;
        }
    }

    &-img-rounded{
        width: 25%;
        height: 0;
        padding-top: 25%;
        border-radius: 99px;
        position: relative;
        background-color: rgba($primary, .7);
        margin: -12.5% auto 0;

        & svg{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50%;
            height: auto;
        }
    }

    &-title{
        font-weight: 900;
        text-transform: uppercase;
        font-size: $font-size-sm;
        @include media-breakpoint-up(lg){
            font-size: $font-size-md;
        }
    }

    &-text{
        color: $gray-600;
        font-size: $font-size-sm;
        
        @include media-breakpoint-up(lg){
            font-size: $font-size-base;
        }

        & a{
            color: $gray-600;
        }
    }
}
p{ @extend .p; }
.p{ font-size: $font-size-md; }

h1{ @extend .h1; }
.h1{ }

h2{ @extend .h2; }
.h2{ }

h3{ @extend .h3;}
.h3{ }

h4{ @extend .h4; }
.h4{}

h5{ @extend .h5; }
.h5{ }

h6{ @extend .h6; }
.h6{}

a{ @extend .a; }
.a{ 
    transition: all 0.3s ease;
    &:hover { cursor: pointer; }

    &--inverted{ color: $secondary;
        &:hover{color: $primary;}
    }
}

.font-bs{ font-size: $font-size-base; }
.font-lg{ font-size: $font-size-lg; }
.font-md{ font-size: $font-size-md; }
.font-sm{ font-size: $font-size-sm; }


